import * as yup from 'yup';
import {AnyObject, Maybe} from 'yup/lib/types';
import {IMAGE_SUFFIX, STRING_LENGTH_REGEX} from '../src/common/constants';

yup.addMethod<yup.StringSchema>(yup.string, 'validateLength', function () {
    return this.matches(STRING_LENGTH_REGEX, {message: 'Požadovaná délka textu je 0 až 255 znaků.'});
});

yup.addMethod<yup.StringSchema>(yup.string, 'image', function () {
    return this.matches(IMAGE_SUFFIX, {message: 'Požadovaný obrázek neodpovídá formátu .jpg, .png.'});
});

yup.addMethod<yup.StringSchema>(yup.string, 'stripEmptyString', function () {
    return this.transform((value) => (value === '' ? undefined : value));
});

yup.addMethod<yup.NumberSchema>(yup.number, 'maximumVal', function (maxVal= 999999) {
    return this.test('maxNumberValue', `Hodnota nemůže být větší než ${maxVal}`, (value) => !value || value <= maxVal);
});

declare module 'yup' {

    interface StringSchema<TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType> extends yup.BaseSchema<TType, TContext, TOut> {
        validateLength(): StringSchema<TType, TContext>;
        stripEmptyString(): StringSchema<TType, TContext>;
        image(): StringSchema<TType, TContext>;
        maximumVal(): NumberSchema<TType, TContext>;
    }

    interface NumberSchema<TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType> extends yup.BaseSchema<TType, TContext, TOut> {
        maximumVal(maxVal?:number): NumberSchema<TType, TContext>;
    }
}

export default yup;
