import React, {useEffect, useRef, useState} from 'react';
import {UseFormGetValues, UseFormRegister} from 'react-hook-form';
import {Payload} from '../../hooks/useContactUsForm';

interface ComponentProps {
  label?: string | null;
  hint?: string | boolean | null;
  identificator: string;
  register?: UseFormRegister<any>;
  error?: any | null;
  required?: boolean | null;
  disabled?: boolean | undefined;
  value?: any;
  type?: string | undefined;
  onChange?: (e: any) => void | null;
  textAlign?: string | undefined;
  className?: string | undefined;
  placeholder?: string | undefined;
  wrapperClassName?: string;
  labelClass?: string;
  getValues: UseFormGetValues<Payload>;
  setValue: (name: any, file: any) => void;
}

const FileInput: React.FC<ComponentProps> = ({
  register,
  identificator,
  error,
  placeholder,
  label,
  hint,
  disabled = false,
  value,
  onChange,
  setValue,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const ref = useRef(null);
  const [showLabel, setShowLabel] = useState<boolean>(false);

  const onChangeAll = (e: any) => {
    // @ts-ignore

    if (register) {
      register(identificator).onChange(e);
    }
    if (onChange) {
      onChange(e);
    }
  };

  const selectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  useEffect(() => {
    setShowLabel(!!selectedFile ?? true);

    if (!selectedFile) {
      setValue(undefined, undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    getBase64(selectedFile).then((data: any) => setValue(data.split(',')[1], selectedFile.name));
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <div className={`input-group ${error && 'error'}`}>
      <input
        className={'position-absolute top-0 bottom-0 start-0 end-0 opacity-0'}
        type={'file'}
        ref={ref}
        style={{ display: 'none' }}
        accept={'.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'}
        onChange={selectFile}
      />
      <input
        type={'text'}
        style={{ cursor: 'pointer' }}
        {...(register ? register(identificator) : null)}
        disabled={disabled}
        // @ts-ignore
        onClick={() => ref.current?.click()}
        defaultValue={value}
        placeholder={placeholder}
        onChange={onChangeAll}
      />
      <label className={`${showLabel && 'show'}`}>{label}</label>
    </div>
  );
};

export default FileInput;
