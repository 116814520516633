import React from 'react';
import {UseFormRegister} from 'react-hook-form';

interface ComponentProps {
  label?: string | null;
  hint?: string | null;
  identificator: string;
  register?: UseFormRegister<any>;
  error?: any | null;
  required?: boolean | null;
  disabled?: boolean | undefined;
  value?: string | undefined;
  rows?: number | undefined;
  placeholder?: string | undefined;
}

const FormTextArea: React.FC<ComponentProps> = ({
  register,
  identificator,
  error,
  label,
  hint,
  required = false,
  value,
  rows = 3,
  disabled = false,
  placeholder,
}) => {
  return (
    <div className={'textarea'}>
      <label>{label}</label>
      <textarea
        rows={rows}
        placeholder={placeholder}
        {...(register ? register(identificator) : null)}
        defaultValue={value}
        disabled={disabled}
        aria-label={identificator}
      />
    </div>
  );
};

export default FormTextArea;
