import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import yup from '../../../../@types/yup.d';

export interface Payload {
  firstname: string;
  lastname: string;
  phone?: string | null;
  note?: string | null;
  email: string;
  file: string;
  fileName: string;
}

export const useContactUsForm = (jobsContact: boolean | null = false) => {
  const validationSchema = yup.object().shape({
    firstname: yup.string().required('Tato pložka je povinná'),
    lastname: yup.string().required('Tato pložka je povinná'),
    email: yup.string().email('Není validní email, napr. petr@novak.cz').required(),
    phone: yup.number(),
    note: yup.string(),
    file: yup.string(),
    fileName: yup.string().when('file', (phone, schema) => {
      // @ts-ignore
      if (jobsContact) {
        return schema.required('Tato pložka je povinná');
      }
    }),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, getValues, formState, setValue, reset } = useForm<Payload>(formOptions);
  const { errors }: { errors: any } = formState;

  return {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    reset,
    getValues,
  };
};
