import React, {useState} from 'react';
import {UseFormGetValues, UseFormRegister} from 'react-hook-form';
import {Payload} from '../../hooks/useContactUsForm';

interface ComponentProps {
  label?: string | null;
  hint?: string | boolean | null;
  identificator: string;
  register?: UseFormRegister<any>;
  error?: any | null;
  required?: boolean | null;
  disabled?: boolean | undefined;
  value?: any;
  type?: string | undefined;
  onChange?: (e: any) => void | null;
  textAlign?: string | undefined;
  className?: string | undefined;
  placeholder?: string | undefined;
  wrapperClassName?: string;
  labelClass?: string;
  getValues: UseFormGetValues<Payload>;
}

const FormInput: React.FC<ComponentProps> = ({
  register,
  identificator,
  error,
  label,
  hint,
  required = false,
  disabled = false,
  value,
  type = 'text',
  onChange,
  textAlign,
  className,
  placeholder,
  wrapperClassName,
  labelClass,
  getValues,
}) => {
  const [showLabel, setShowLabel] = useState<boolean>(false);

  const onChangeAll = (e: any) => {
    // @ts-ignore
    setShowLabel(getValues(identificator) ?? true);

    if (register) {
      register(identificator).onChange(e);
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={`input-group ${error && 'error'}`}>
      <input
        placeholder={placeholder}
        {...(register ? register(identificator) : null)}
        disabled={disabled}
        defaultValue={value}
        onChange={onChangeAll}
      />
      <label className={`${showLabel && 'show'}`}>{label}</label>
    </div>
  );
};

export default FormInput;
